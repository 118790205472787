import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const Content = () => (
    <StaticQuery query={graphql`
    {
        home: wordpressPage(title: { eq: "Home" }){
            acf {
                top_background_image {
                    source_url
                }
            }
        }
        awards: wordpressPage(slug: { eq: "awards" }) {
            acf {
                title
                subtitle
            }
        }
        allWordpressWpAwards(sort:{order:DESC, fields:date}) {
            edges {
              node {
                id
                title
                featured_media {
                    source_url
                }
              }
            }
        }
    }
    `} render={props => (
         <div className="frame">
            <div className="awards-content">
                <div className="top-background-image">
                    <img src={props.home.acf.top_background_image.source_url} />
                </div>
                <div className="title" data-aos="fade-up">{ props.awards.acf.title }</div>
                <div className="subtitle" data-aos="fade-up">{ props.awards.acf.subtitle }</div>
                <div className="all-awards">
                    {
                        props.allWordpressWpAwards.edges.map((award, index) => (
                            <div className="item" key={award.node.id} data-aos="fade-up" data-aos-delay={ index * 200 } data-aos-duration="800">
                                <div className="item-inner">
                                    <img src={ award.node.featured_media.source_url } />
                                    <br/>
                                    <br/>
                                    { award.node.title }
                                </div>
                            </div>   
                        ))
                    }
                </div>
            </div>
         </div>
    ) } />
);

export default Content;